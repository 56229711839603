import * as React from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

function Svg({ props }) {
  return (
    <SvgIcon sx={{ fontSize: "20px" }} {...props}>
      <svg viewBox="0 0 464 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.205 495.22H427.473L455.513 441.639L427.473 388.029H134.205V495.22Z"
          fill="#FFD772"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.205 495.22V388.029V383.921C134.205 381.077 131.878 378.778 129.034 378.778H13.629C10.785 378.778 8.48602 381.076 8.48602 383.921V499.329C8.48602 502.145 10.756 504.472 13.571 504.5H13.628H129.033H129.09C131.905 504.471 134.204 502.144 134.204 499.329V495.22H134.205Z"
          fill="#F9F7F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.629 318.847H129.034C131.878 318.847 134.205 316.52 134.205 313.704V309.596V202.376V198.296C134.205 195.452 131.878 193.125 129.034 193.125H13.629C10.785 193.125 8.48602 195.452 8.48602 198.296V313.704C8.48602 316.52 10.785 318.847 13.629 318.847V318.847Z"
          fill="#F9F7F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.205 202.375V309.596H427.473L455.513 255.986L427.473 202.375H134.205Z"
          fill="#75CEF8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.205 123.943H427.473L455.513 70.361L427.473 16.751H134.205V123.943Z"
          fill="#EB5468"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.205 123.943V16.751V12.643C134.205 9.799 131.878 7.5 129.034 7.5H13.629C10.785 7.5 8.48602 9.798 8.48602 12.643V128.051C8.48602 130.895 10.784 133.222 13.629 133.222H129.034C131.878 133.222 134.205 130.895 134.205 128.051V123.943Z"
          fill="#F9F7F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.719 16.751V123.943V128.051C97.719 130.895 95.392 133.222 92.548 133.222H129.034C131.878 133.222 134.205 130.895 134.205 128.051V123.943V16.751V12.643C134.205 9.799 131.878 7.5 129.034 7.5H92.548C95.392 7.5 97.719 9.798 97.719 12.643V16.751Z"
          fill="#DDDAEC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M129.034 193.124H92.548C95.392 193.124 97.719 195.451 97.719 198.295V202.375V309.595V313.703C97.719 316.519 95.392 318.846 92.548 318.846H129.034C131.878 318.846 134.205 316.519 134.205 313.703V309.595V202.375V198.295C134.205 195.452 131.878 193.124 129.034 193.124V193.124Z"
          fill="#DDDAEC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M129.034 378.778H92.548C95.392 378.778 97.719 381.076 97.719 383.921V388.029V495.22V499.328C97.719 502.144 95.421 504.471 92.605 504.499H129.034H129.091C131.906 504.47 134.205 502.143 134.205 499.328V495.22V388.029V383.921C134.205 381.076 131.878 378.778 129.034 378.778Z"
          fill="#DDDAEC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M381.851 16.751C388.516 35.138 392.251 55.623 392.251 77.228C392.251 93.604 390.125 109.319 386.189 123.943H427.473L455.513 70.362L427.473 16.752H381.851V16.751Z"
          fill="#E5384F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M381.851 202.375C388.516 220.762 392.251 241.247 392.251 262.88C392.251 279.227 390.125 294.943 386.189 309.595H427.473L455.513 255.985L427.473 202.375H381.851Z"
          fill="#60B7FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M381.851 388.029C388.516 406.416 392.251 426.901 392.251 448.506C392.251 464.853 390.125 480.597 386.189 495.221H427.473L455.513 441.64L427.473 388.03H381.851V388.029Z"
          fill="#FBBA58"
        />
        <path
          d="M13.629 140.723H129.034C134.845 140.723 139.742 136.786 141.23 131.443H427.473C430.264 131.443 432.824 129.893 434.119 127.421L462.159 73.84C463.299 71.662 463.299 69.065 462.159 66.886L434.119 13.276C432.826 10.802 430.265 9.252 427.473 9.252H302.961C298.818 9.252 295.461 12.61 295.461 16.752C295.461 20.894 298.818 24.252 302.961 24.252H422.933L447.049 70.361L422.934 116.443H141.705V24.251H267.882C272.025 24.251 275.382 20.893 275.382 16.751C275.382 12.609 272.025 9.251 267.882 9.251H141.229C139.738 3.924 134.842 0 129.034 0H13.629C6.65802 0 0.986023 5.671 0.986023 12.643V128.051C0.986023 135.038 6.65802 140.723 13.629 140.723V140.723ZM15.986 15H126.705V125.723H15.986V15Z"
          fill="black"
        />
        <path
          d="M434.119 198.899C432.826 196.425 430.265 194.875 427.473 194.875H141.223C139.725 189.547 134.835 185.624 129.034 185.624H13.629C6.65802 185.624 0.986023 191.309 0.986023 198.295V313.703C0.986023 320.674 6.65702 326.346 13.629 326.346H129.034C134.842 326.346 139.737 322.422 141.228 317.095H427.473C430.265 317.095 432.825 315.545 434.119 313.071L462.159 259.461C463.299 257.284 463.299 254.686 462.159 252.509L434.119 198.899ZM126.705 311.347H15.986V200.625H126.705V311.347ZM422.932 302.096H141.705V209.876H422.932L447.049 255.986L422.932 302.096Z"
          fill="black"
        />
        <path
          d="M77.235 35.251H65.428C61.286 35.251 57.928 38.609 57.928 42.751C57.928 46.893 61.286 50.251 65.428 50.251H69.736V97.941C69.736 102.083 73.094 105.441 77.236 105.441C81.378 105.441 84.736 102.083 84.736 97.941V42.751C84.735 38.609 81.378 35.251 77.235 35.251V35.251Z"
          fill="black"
        />
        <path
          d="M86.39 276.498C81.744 276.558 76.847 276.601 72.509 276.619C75.429 272.679 79.247 267.417 84.152 260.407C87.773 255.243 90.145 250.34 91.203 245.834C91.266 245.564 91.315 245.291 91.348 245.015L91.607 242.86C91.643 242.563 91.661 242.264 91.661 241.965C91.661 230.051 81.956 220.359 70.026 220.359C59.704 220.359 50.785 227.695 48.817 237.802C48.025 241.868 50.68 245.806 54.745 246.597C58.814 247.39 62.749 244.735 63.54 240.669C64.139 237.592 66.867 235.358 70.026 235.358C73.561 235.358 76.46 238.126 76.651 241.599L76.511 242.763C76.02 244.615 74.811 247.601 71.867 251.8C63.101 264.328 57.896 271.134 55.1 274.792C51.631 279.33 49.72 281.829 50.955 285.897C51.69 288.316 53.508 290.18 55.987 291.025C56.987 291.358 57.836 291.642 68.622 291.642C72.866 291.642 78.648 291.598 86.582 291.496C90.724 291.443 94.039 288.042 93.985 283.9C93.932 279.759 90.538 276.412 86.39 276.498Z"
          fill="black"
        />
        <path
          d="M71.346 405.869C61.055 405.869 52.126 413.204 50.115 423.31C49.306 427.372 51.944 431.321 56.006 432.13C60.072 432.942 64.018 430.301 64.826 426.239C65.446 423.127 68.188 420.869 71.347 420.869C74.99 420.869 77.953 423.846 77.953 427.504C77.953 431.163 74.99 434.139 71.347 434.139C67.205 434.139 63.847 437.497 63.847 441.639C63.847 445.781 67.205 449.139 71.347 449.139C74.99 449.139 77.953 452.103 77.953 455.745C77.953 459.404 74.99 462.38 71.347 462.38C68.053 462.38 65.232 459.939 64.793 456.76C64.735 456.318 64.712 456.024 64.712 455.745C64.712 451.603 61.354 448.245 57.212 448.245C53.07 448.245 49.712 451.603 49.712 455.745C49.712 456.689 49.775 457.599 49.926 458.753C51.393 469.372 60.601 477.38 71.347 477.38C83.261 477.38 92.953 467.674 92.953 455.745C92.953 450.354 90.964 445.422 87.688 441.633C90.964 437.839 92.953 432.901 92.953 427.503C92.952 415.574 83.259 405.869 71.346 405.869V405.869Z"
          fill="black"
        />
        <path
          d="M175.317 60.02H221.571C225.713 60.02 229.071 56.662 229.071 52.52C229.071 48.378 225.713 45.02 221.571 45.02H175.317C171.175 45.02 167.817 48.378 167.817 52.52C167.817 56.662 171.175 60.02 175.317 60.02Z"
          fill="black"
        />
        <path
          d="M175.317 96.8521H269.347C273.49 96.8521 276.847 93.4941 276.847 89.3521C276.847 85.2101 273.49 81.8521 269.347 81.8521H175.317C171.175 81.8521 167.817 85.2101 167.817 89.3521C167.817 93.4941 171.175 96.8521 175.317 96.8521V96.8521Z"
          fill="black"
        />
        <path
          d="M175.317 244.438H221.571C225.713 244.438 229.071 241.08 229.071 236.938C229.071 232.796 225.713 229.438 221.571 229.438H175.317C171.175 229.438 167.817 232.796 167.817 236.938C167.817 241.08 171.175 244.438 175.317 244.438Z"
          fill="black"
        />
        <path
          d="M269.347 266.298H175.317C171.175 266.298 167.817 269.656 167.817 273.798C167.817 277.94 171.175 281.298 175.317 281.298H269.347C273.49 281.298 276.847 277.94 276.847 273.798C276.847 269.656 273.49 266.298 269.347 266.298Z"
          fill="black"
        />
        <path
          d="M175.317 431.24H221.571C225.713 431.24 229.071 427.882 229.071 423.74C229.071 419.598 225.713 416.24 221.571 416.24H175.317C171.175 416.24 167.817 419.598 167.817 423.74C167.817 427.882 171.175 431.24 175.317 431.24Z"
          fill="black"
        />
        <path
          d="M175.317 468.072H269.347C273.49 468.072 276.847 464.714 276.847 460.572C276.847 456.43 273.49 453.072 269.347 453.072H175.317C171.175 453.072 167.817 456.43 167.817 460.572C167.817 464.714 171.175 468.072 175.317 468.072V468.072Z"
          fill="black"
        />
        <path
          d="M434.119 384.553C432.825 382.079 430.265 380.529 427.473 380.529H302.961C298.818 380.529 295.461 383.887 295.461 388.029C295.461 392.171 298.818 395.529 302.961 395.529H422.933L447.049 441.638L422.934 487.721H141.705V395.53H267.882C272.025 395.53 275.382 392.172 275.382 388.03C275.382 383.888 272.025 380.53 267.882 380.53H141.229C139.738 375.203 134.843 371.279 129.035 371.279H13.629C6.65802 371.279 0.986023 376.951 0.986023 383.922V499.33C0.986023 506.317 6.65702 512.001 13.629 512.001H129.034C134.845 512.001 139.742 508.064 141.23 502.721H427.473C430.264 502.721 432.824 501.171 434.119 498.699L462.159 445.117C463.299 442.939 463.299 440.341 462.159 438.163L434.119 384.553ZM126.705 497H15.986V386.277H126.705V497Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
}

export default function ListIcon() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Svg />
    </Box>
  );
}
